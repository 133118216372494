.navbar-container{
    position: fixed;
    top: 0;
    /* left: 0; */  

    width: 100%;
    height: clamp(50px, var(--navbarHeight), var(--navbarHeight));

    padding: 0rem 5vw ;

    /* border: 3px solid var(--brandColourRed); */
    /* border-bottom: 2px solid var(--brandColourBlue); */

    background-color: var(--white);
    /* background-color: #ffbcd6; */
    /* background: hsl(0 0% 100% / 0.5); */
    /* backdrop-filter: blur(5px); */
    /* background-color: var(--backgroundColour2); */

    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-items: center;

    transition: 0.6s ease-in-out;
    border-bottom: 6px solid #ff6480;


    z-index: 10;
}

.hidden{
    top:calc(-1 * var(--navbarHeight));
    transition: 0.2s ease-in-out;
}

.navbar-menu-container{
    width: 100%;
    height: 100vh;

    padding-top: var(--navbarHeight);

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: var(--brandColourRed); */
    background-color: white;
    /* opacity: 0.5; */
    z-index: 9;

    transform: translateX(0);
    transition: 0.6s ease-in-out;
}

.menu-hidden{
    /* top: -100%; */
    transform: translateX(100%);
    transition: 0.6s ease-in-out;
}

/* .navbar-container:after{
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    bottom: 0%;
    left: 0%;
    transform: translate(70%,0%);
    transform-origin: 0%, 50%;
    background-color: var(--black);
} */

.navbar-logo-box{
    /* width: clamp(50px, var(--navbarHeight), var(--navbarHeight)); */
    width: 14vh;
    height: inherit;

    margin-right: 3rem;
    font-size: 3rem;

    display: grid;
    place-items: center;

    /* padding: 1rem; */

    object-fit: contain;

}

.nav-logo-img{
    width: 100%;
    height: auto;
    /* aspect-ratio: 1/0.8; */
    
    object-fit: contain;
    
    cursor: pointer;
    /* object-position: 100% 50%; */
    /* border: 1px solid red; */
}

.navbar-nav-box{
    width: clamp(100px, 30vw, 30vw);
    
    /* margin-bottom: 1rem; */
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* align-self: flex-end; */

    /* border: 1px solid red; */
}

.nav-btn{
    position: relative;
    min-width: 2rem;
    height: 2rem;

    padding: 0.5rem 1rem;
    margin: 0 0.8rem;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Quicksand", sans-serif;
    /* font-size: 1.3rem; */
    color: #363636;
    color: var(--brandColourRed);
    /* color: white; */
    font-weight: 500;

    cursor: pointer;
    
    transition: all 0.3s ease-in-out;
}

.nav-btn-mobile{
    position: relative;
    width: 100%;
    height: calc((100vh / 5) - 5vh);
    /* color: white; */

    font-size: 2rem;

    /* border: 1px solid black; */
    margin: 0 0 5px 0;
    padding: 5vh 0;
}
  
.nav-btn::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--brandColourRed);
    transform-origin: bottom right;
    transition: transform 0.35s ease-out;
}

/* .nav-btn:nth-child(even){
    color: var(--brandColourBlue);
} */
.nav-btn:nth-child(even):after{
    background-color: var(--brandColourBlue);
}
  
.nav-btn:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 1200px ){
    .navbar-nav-box{
       width: clamp(150px, 50vw, 50vw);
    }
}