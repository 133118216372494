

.cover-carousel-container{
    position: relative;
    width: 100%;
    aspect-ratio: 1920/700;
    /* height: calc(100vh - var(--navbarHeight)); */
    /* height: 65vh; */
    margin-top: var(--navbarHeight);

    /* border: 1px solid red; */

    display: flex;
    justify-content: center;
    align-items: center;

    /* background-image: linear-gradient(90deg, #363636 50%, #fff 50%); */

    overflow: hidden;
}

.carousel-inner-container{
    position: absolute;
    top: 0;
    left: 0;
    /* height: calc(10vh - var(--navbarHeight)); */
    height: 100%;
    
    display: flex;
    align-items: flex-start;

    transition: 0.6s cubic-bezier(0.6,0.01,0.22,0.93);

    transform-origin: 0%,50%;

    /* border: 2px solid blue; */
}

.cover-carousel-item-container{
    /* width: 100%; */
    width: 100vw;
    aspect-ratio: 1920/700;
    /* height: inherit; */
    
    /* display: inline-flex; */
    /* display: inline-flex; */
    /* align-items: center; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* justify-content: center; */
    
    /* background-color: #ffc; */
    /* border: 1px solid red; */

}

.banner-img{
    width: 100%;
    aspect-ratio: 1920/700;
    /* height: 100%; */

    object-fit: contain;
}

.carousel-arrow-btn-left{
    position: absolute;
    top: 50%;
    left: 0;

    padding: 0;

    width: 2.2rem;
    height: 2.2rem;

    font-size: 1.3rem;
    color: var(--brandColourRed);

    display: grid;
    place-content: center;

    border: 1px solid var(--brandColourRed);
    border-radius: 50%;

    transform-origin: 50% 50%;
    transform: translate(2rem, -50%);
    
    cursor: pointer;
}

.carousel-arrow-btn-right{
    position: absolute;
    top: 50%;
    right: 0;

    padding: 0;

    width: 2.2rem;
    height: 2.2rem;

    font-size: 1.3rem;
    color: var(--brandColourRed);

    display: grid;
    place-content: center;

    border-radius: 50%;
    border: 1px solid var(--brandColourRed);

    transform-origin: 50% 50%;
    transform: translate(-2rem, -50%);

    cursor: pointer;
}

.carousel-btn-box{
    position: absolute;
    bottom: 0;
    left: 100%;

    width: 20vw;
    height: 2.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* place-content: center; */

    transform: translate(-100%,0%);

    /* background-color: antiquewhite; */
    /* border: 1px solid red; */
    /* border-radius: 100% 0% 0 0%; */
}

.carousel-btn{
    font-size: 1.5rem;
    color: var(--white);
    color: var(--brandColourRed);

    margin: 0 0.5rem;

    cursor: pointer;
}