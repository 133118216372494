.marquee-container{
    width: 80%;
    height: 30vh;

    overflow: hidden;
    display: flex;
    flex-direction: row;

    user-select: none;

    /* border: 1px solid red; */
}

.marquee-group{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;

    /* border: 1px solid green; */

    animation: marquee-auto 20s linear infinite;
}

@keyframes marquee-auto{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

.marquee-img-group{
    display: grid;
    place-items: center;
    width: clamp(10rem, 1rem + 40vmin, 30rem);
    padding: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 10);

    cursor: pointer;
}

.marquee-item-img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    padding: 5px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}