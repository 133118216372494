.brand-container{
    position: relative;

    width: 100%;
    min-height: 100vh;

    padding-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* border: 1px solid red; */
}

.brand-grid-box{
    width: 100%;
    height: 70vh;

    margin: 5vh 0 0 0;

    display: grid;
    grid-template-columns: 40% 55%;
    column-gap: 5%;

    /* background-color: red; */
}

.brand-grid-img-box{
    position: relative;
    width: 100%;
    height: 70vh;

    object-fit: contain;

    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(179.4deg, rgb(255, 206, 213) 2.2%, rgb(255, 194, 203) 96.2%);
    /* rgb(255, 206, 213) 2.2% -> rgb(255, 194, 203) 96.2%*/
    border: 5px solid #FFC2D1;
    border-radius: 0 30px 30px 0;
}

.brand-grid-img-box>img{
    height: 100%;
    width: auto;
    max-width: 100%;

    object-fit: cover;
}

.brand-grid-content-box{
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 5vh;
    padding-right: 5vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    /* border: 1px solid red; */
}

.brand-logo-box{
    position: absolute;
    top: 0vh;
    left: 0vw;

    width: 17vw;
    height: 30vh;

    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.brand-logo-box-mobile{
    position: relative;
    width: 100%;
    height: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 2px solid blue; */
}

.brand-logo-box-mobile>img{
    width: 80%;
    height: 80%;
    object-fit: scale-down;

    /* border: 1px solid red; */
}

.brand-logo-box>img{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.brand-grid-content-box>h1{
    /* font-family: "Quicksand", sans-serif; */
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 5rem;
}

.brand-grid-content-text{
    width: 20vw;
    min-height: 10vh;

    position: absolute;
    top: 30vh;
    left: 2%;

    /* border: 1px solid red; */
}

.brand-grid-content-text>p{
    font-family: Quicksand, sans-serif;
    font-size: 1.2rem;
}

.next-brand-box{
    position: absolute;
    bottom: 0;
    right: 0;

    width: 25vw;
    height: 30vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(253, 240, 233);
    /* background: linear-gradient(179.4deg, rgb(253, 240, 233) 2.2%, rgb(255, 194, 203) 96.2%); */
    filter: grayscale(0.8);
    
    /* animation: slideTrans 1500 ease-in-out;
    animation-play-state: paused;
    
    overflow: hidden; */
    border: 2px solid black;
    /* border-right: none; */
    border-radius: 30px 0 0 30px;
}

.next-brand-coverImg{
    height: 100%;
    width: auto;

    object-fit: cover;

    transition: 0.6 ease-in-out;
}

.brand-next-logo{
    position: absolute;
    top: 50%;
    left: 0;

    transform-origin: 50% 50%;
    transform: translate(50%, -50%);

    width: 50%;
    height: auto;

    object-fit: contain;

    /* border: 1px solid blue; */
}

.next-brand-arrow-container{
    position: absolute;
    bottom: calc(30vh);
    right: 0;
    width: 25vw;
    height: 8vh;

    padding-right: 2rem;

    /* background-color: red; */

    display: flex;
    justify-content: flex-end;
    align-items: center;

    z-index: 5;
}
.next-icon{
    font-size: 2.5rem;
    font-weight: 300;
    margin-right: 1rem;
    color: gray;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.next-icon:hover{
    color: var(--brandColourRed);
    transition: 0.3s ease-in-out;
}

.gallery-arrow-img{
    width: 5vw;
    height: auto;

    /* opacity: 0.5; */

    /* background-color: red; */

    transform: rotate(270deg) scaleX(-1) translate(30%, 18%);

    object-fit: contain;
    z-index: 5;
}
.gallery-arrow{
    color: var(--backgroundColour2);
    cursor: default;
}

.gallery-arrow:hover{
    color: var(--backgroundColour2);
}


.next-brand-arrow-container>p{
    font-family: "Quicksand", sans-serif;
    font-weight: 300;

    font-size: 1.8rem;

    justify-self: flex-end;
}

.brand-galery-heading-box{
    position: absolute;

    
    bottom: 5vh;
    left: 40vw;
    
    width: 15vw;
    height: 10vh;

    transform-origin: 100% 100%;
    transform: translate(-100%, -10%) rotate(90deg);

    display: flex;
    justify-content: center;
    align-items: center;
    
    /* opacity: 0.3; */
    
    /* background-color: red; */
}

.brand-galery-heading-box>p{
    font-family: "Sofia", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 2.8rem;

    color: #FFC2D1;

}

.brand-image-grid{
    position: relative;
    width: 100%;
    height: 100%;

    margin: 15vh 0;

    padding: 0 3vw;
    
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2vw;
    row-gap: 5vh;

    /* border: 1px solid red; */
}

.brand-portfolio-box{
    width: 100%;
    /* height: 60vh; */
    aspect-ratio: 2/3;

    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.brand-portfolio-img{
    width: 100%;
    height: 100%;

    object-fit: cover;
    transition: 0.6s ease-in-out;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .brand-grid-box{
        position: relative;
        width: 100%;
        height: 60vh;
    
        margin: 0vh 0 0 0;
    
        display: grid;
        grid-template-columns: 1fr;
    }

    .brand-grid-img-box{
        border: none;
        height: 60vh;
        border-bottom: 5px solid #FFC2D1;
        border-radius: 0;
    }

    .brand-grid-img-box>img{
        object-fit: scale-down;
        object-position: 50% 50%;
    }

    .brand-image-grid{
        width: 100%;
        height: 100%;
    
        margin: 30vh 0 10vh;
    
        padding: 0 3vw;
        
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        column-gap: 2vw;
        row-gap: 5vh;
    
        /* border: 1px solid red; */
    }
}