.contact-info-section{
    position: relative;
    width: 35vw;
    min-height: 80vh;

    padding: 0 5vw;
    /* margin-top: 10vh; */
    /* background-color: red; */
    border: 3px solid #ff4d6d;
    border-radius: 0 20px 20px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    gap: 1rem;
}

.contact-info-section>h3{
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;

    font-family: "Quicksand", sans-serif;
    font-size: 2.5rem;
    color: #ff4d6d;
}


.contact-item{
    font-family: "Quicksand", sans-serif;

    display: flex;
    align-items: center;

    gap: 1rem;

    /* border: 1px solid blue; */
} 

.contact-icon{
    font-size: 2rem;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: var(--brandColourRed);
    /* color: #ff4d6d; */
    color: white;
    /* outline: 2px solid #111; */
}

.contact-insta{    
    font-size: 2.5rem;
    transition: 0.3s ease-in-out;
}

.contact-fb{
    font-size: 2.5rem;
}

.contact-insta:hover, .contact-fb:hover{
    /* transform: scale(1.05); */
    /* font-size: 2rem; */
    color: var(--brandColourRed);
    background-color: white;
    border: 2px solid var(--brandColourRed);
    transition: 0.3s ease-in-out;
}

.contact-link{
    font-family: "Quicksand", sans-serif;
    font-size: 1.2rem;

    transition: 0.3s ease-in-out;

    display: flex;
    flex-direction: column;
}

.contact-link>p{
    font-family: "Quicksand", sans-serif;
    font-size: 1.2rem;

}

.contact-link:hover{
    color: #ff4d6d;

    transition: 0.3s ease-in-out;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .contact-info-section{
        position: relative;
        width: 100%;
        min-height: 80vh;
    
        padding: 0 5vw;
        /* margin-top: 10vh; */
        /* background-color: red; */
        border: 3px solid #ff4d6d;
        border-radius: 0 0px 20px 20px;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    
        gap: 1rem;
    }
    .contact-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        gap: 0.5rem;
    } 

    .contact-link>p{
        text-align: center;
    }
} 