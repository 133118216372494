:root{
    --headingHeight:6rem;
}

.half-carousel-container{
    position: relative;

    width: 100%;
    height: 60vh;

    /* padding: 5vh 0vw; */

    /* background-color: red; */

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    /* overflow: scroll; */

    /* border: 1px solid red; */

    /* background-image: linear-gradient(#363636 50%, #fff 50%); */
}

.half-carousel-heading-container{
    position: relative;
    width: 100%;
    min-height: var(--headingHeight);

    /* margin-top: 2rem; */
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    align-self: flex-start;

    /* color: var(--black); */
    
    background-color: #d4ebff;
    overflow: hidden;

    /* background-color: #E78895; */
}

.half-carousel-heading-container>h2{
    /* font-family: "Montserrat", sans-serif;
    font-weight: 300; */
    font-size: 2.2rem;

    font-family: "Sofia", cursive;
    font-weight: 400;
    font-style: normal;
    /* color: #a4133c; */
    color: #ff4d6d;

    z-index: 3;

    /* color: #f5cac3; */
    /* color: var(--white); */
    /* color: #FFF7F1; */
}

.half-carousel-heading-container>h1{
    letter-spacing: 0.0015em;
	font-size: 5em;
    /* font-family: "Sofia", cursive; */
    font-family: "Quicksand", sans-serif;

    font-weight: 700;
    color: var(--brandColourRed);
    --shadowMain : #fc9cbf;
    --shadowLight: #ffc8dd;
	text-shadow: 0 1px var(--shadowMain), -1px 0 var(--shadowLight), -1px 2px var(--shadowMain), -2px 1px var(--shadowLight),
		-2px 3px var(--shadowMain), -3px 2px var(--shadowLight), -3px 4px var(--shadowMain), -4px 3px var(--shadowLight),
		-4px 5px var(--shadowMain), -5px 4px var(--shadowLight), -5px 6px var(--shadowMain), -6px 5px var(--shadowLight),
		-6px 7px var(--shadowMain), -7px 6px var(--shadowLight), -7px 8px var(--shadowMain), -8px 7px var(--shadowLight);
	text-align: center;

    z-index: 3;
    /* 25px 25px 0px #6868AC,  */
    /* 30px 30px 0px #90B1E0; */
}

.half-carousel-left-cover{
    position: absolute;
    top: var(--headingHeight);
    left: 0;

    width: 5vw;
    height: calc(100% - var(--headingHeight));

    background-color:var(--backgroundColour1);
}

.half-carousel-right-cover{
    position: absolute;
    top: var(--headingHeight);
    right: 0;

    width: 5vw;
    height: calc(100% - var(--headingHeight));

    background-color:var(--backgroundColour1);
}

.half-carousel-inner-container{
    position: absolute;
    top: var(--headingHeight);
    left: 5vw;
    height: calc(60vh - var(--headingHeight));
    
    display: flex;
    align-items: flex-start;

    transition: 0.6s ease-in-out;

    transform-origin: 0%,50%;

    /* border: 1px solid green; */

    /* background-color: antiquewhite; */
}

.half-carousel-item-container{
    width: 18vw;
    height: 100%;

    padding: 1rem;

    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: calc(100% - var(--headingHeight)) var(--headingHeight);

    /* border: 1px solid blue; */
}

.half-carousel-img{
    width: 90%;
    aspect-ratio: 1/ 1;

    object-fit: contain;

    justify-self: center;
    align-self: center;

    border: 1px solid var(--backgroundColour2);
    border-radius: 50%;

    /* background-color: green; */
}

.half-carousel-name{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--black);

    display: flex;
    justify-content: center;
    align-items: center;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .half-carousel-heading-container>h1{
        font-size: 3em;
    
        font-weight: 600;
    }
}