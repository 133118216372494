.brand-half-section{
    position: relative;

    width: 100%;
    height: clamp(60vh, auto, 80vh);

    /* margin-top: 25vh; */
    /* padding: 5vh 15vw; */
    /* padding: 5vh 0; */
    /* margin: 10vh 0; */

    display: grid;
    /* grid-template-columns: 25% 75%; */
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;


}

/* //////////////////////////////////////////////////////////// */

/* .custom-shape-divider-top-1714998883 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1714998883 svg {
    position: relative;
    display: block;
    width: calc(170% + 1.3px);
    height: 120px;
}

.custom-shape-divider-top-1714998883 .shape-fill {
    fill: #FFFFFF;
} */

.custom-shape-divider-bottom-1715064441 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1715064441 svg {
    position: relative;
    display: block;
    width: calc(135% + 1.3px);
    height: 116px;
}

.custom-shape-divider-bottom-1715064441 .shape-fill {
    /* fill: #ffafcc; */
    fill: #fff;
}




.custom-shape-divider-top-1715065671 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1715065671 svg {
    position: relative;
    display: block;
    width: calc(122% + 1.3px);
    height: 116px;
}

.custom-shape-divider-top-1715065671 .shape-fill {
    fill: #FFFFFF;
}

/* /////////////////////// */
.custom-shape-divider-top-test {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-test svg {
    position: relative;
    display: block;
    width: calc(152% + 1.3px);
    height: 120px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-test .shape-fill {
    fill: #fff;
}
/* //////////////////////////////////////////////////////////// */


.decorative-img{
    position: absolute;
    top: 0;
    right: 5%;

    width: 5vw;
    height: auto;

    object-fit: contain;

    transform: rotate(-30deg);

    /* z-index: 4; */
}


.star{
    position: absolute;
    top: 25%;
    left: 25%;

    width: 3rem;
    aspect-ratio: 1/1;
}

.star>img{
    width: 100%;
    height: 100%;

    object-fit: contain;
}


/* /////////////////////////////////// */


.brand-half-img-container{
    position: relative;

    width: calc(100% - 5vw);
    min-height: 70vh;
    padding: 10vh 0;
    /* height: 100%; */
    /* border: 1px solid red; */
    border-radius: 0px 30px 30px 0px;
    border: 5px solid #bde0fe;
    
    /* padding: 1rem 1rem 3rem 1rem; */

    background-image: 
        radial-gradient(#ffe5ec 10%, transparent 10%),
        radial-gradient(#bde0fe 10%, transparent 10%);
    /* background-color: #e53935; */
    background-color: #9dd6f0;
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* transform: rotate(1.5deg) scale(1); */
    transition: 0.3s ease-in-out;
    
    /* border-radius: 15px; */

    /* background: linear-gradient(to top, #e6b980 0%, #eacda3 100%); */

    
    /* box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;    border: 1px solid green; */
    /* border-radius: 0% 30% 30% 0%; */
    cursor: pointer;
    overflow: hidden;
    
}


.right-brand-half-section{
    /* grid-template-columns: 75% 25%; */
    grid-template-columns: 50% 50%;
    /* border: 1px solid blue; */
}

.brand-half-img-container>h3{
    font-family: "Sofia", cursive;
    font-weight: 400;
    font-style: normal;

}

.right-half-img-container{
    border-radius: 30px 0% 0% 30px;
    justify-self: flex-end;

}

.brand-half-img{
    width: 30vw;
    height: 50vh;

    background: linear-gradient(179.4deg, rgb(255, 206, 213) 2.2%, rgb(255, 194, 203) 96.2%);

    /* border: 5px solid black; */
    border: 5px solid #ffafcc;
    
    /* background-color: var(--white); */

    /* border: 1px solid blue; */

    border-radius: 20px;

    object-fit: scale-down;
    transition: 0.3s ease-in-out;
}


.brand-half-img:hover{
    transform: scale(1.01);

    transition: 0.3s ease-in-out;

}

.brand-half-text{
    position: relative;

    width: clamp(10px, auto, 40vw);
    min-height: 20vw;

    padding: 5vh 10vw;

    /* border-radius: 30px 0 0 30px; */
    border: 5px solid #ffdcd7;
    border-radius: 20px;
    background-color: #fef9ef;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    justify-self: center;

    z-index: 0  ;
    /* background-color: var(--backgroundColour2); */
}

/* .brand-half-text::before{
    content: '';
    width: 50%;
    height: 50px;

    position: absolute;
    bottom: 0px;
    left: 0;

    transform: translate(50%, 0);

    background:
        linear-gradient(135deg, white 25%, transparent 25%) -25px 0,
        linear-gradient(225deg, white 25%, transparent 25%) -25px 0,
        linear-gradient(315deg, white 25%, transparent 25%),
        linear-gradient(45deg, white 25%, transparent 25%)
        ;
    background-size: 50px 50px;
    background-color: #EC173A;
} */

.brand-half-logo-img{
    position: relative;

    width: 20vw;
    min-height: 15vh;
    max-height: 18vh;

    object-fit: contain;
    /* cursor: pointer; */
    /* border: 1px solid blue; */
}

.brand-explore-btn{
    position: relative;
    padding: 0.5rem 1.5rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: #fee6e3;
    border: 2px solid #111;
    border-radius: 15px;  
    

    font-family: Quicksand, sans-serif;
    color: #111;
    
    cursor: pointer;
    
    line-height: 24px;
}

.brand-explore-btn::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;

    width: 100%;
    height: 100%;
    
    background-color: #111;
    border-radius: 15px;
    display: block;

    transform: translate(8px, 8px);
    transition: 0.3s ease-in-out;
    z-index: -1;
}

.brand-explore-btn:hover::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;

    width: 100%;
    height: 100%;
    
    background-color: #111;
    border-radius: 15px;
    display: block;

    transform: translate(2px, 2px);
    transition: 0.3s ease-in-out;
    z-index: -1;
}

/* //////////////////////////// */

.button-56 {
    background-color: #fee6e3;
border: 2px solid #111;
border-radius: 8px;
box-sizing: border-box;

font-family: Inter,sans-serif;
font-size: 16px;

height: 48px;
  
  max-width: 100%;
  position: relative;
  
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56::after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-56:hover::after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}
/* ///////////////////////////// */

.brand-half-text>h1{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}

.right-half-text{
    justify-content: space-between;
    /* border-radius: 0px 30px 30px 0px; */
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .brand-half-section{
        width: 100%;
        height: clamp(60vh, auto, 80vh);

        display: grid;
        /* grid-template-columns: 25% 75%; */
        grid-template-columns: 1fr;

        margin-bottom: 10vh;
    }

    .brand-half-img-container{
        position: relative;
    
        width: calc(100% - 10vw);
        height: 50vh;
        min-height: 50vh;
        padding: 10vh 0;

        margin: 0 5vw;
        border-radius: 50px 50px 0 0;        
       border-bottom: 0px;
    }

    .brand-half-img{
        width: 60vw;
        height: 40vh;
    
    }

    .brand-half-text{
        position: relative;
    
        /* width: clamp(10px, auto, 0vw); */
        width: calc(100% - 10vw);

        min-height: 20vw;
    
        padding: 5vh 10vw;
        border-radius: 0 0 50px 50px;
        border-top: 0px;
        
        /* background-color: var(--backgroundColour2); */
    }

    .brand-half-logo-img{
    
        width: 50vw;
        min-height: 15vh;
        max-height: 18vh;
    
        object-fit: scale-down;
        /* cursor: pointer; */
        /* border: 1px solid blue; */
    }
}