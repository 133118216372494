.contact-form-container{
    width: 100%;
    min-height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    /* align-items: center; */

    /* padding: 5vh 1rem; */

    /* background-color: var(--backgroundColour2); */
}

.form-container{
    width: 35vw;
    height: 100%;
    min-height: 75vh;

    border-radius: 20px 0 0 20px;
    background-color: #ff6480;
    color: var(--white);

    
    padding: 1.5rem;
    
    /* border: 1px solid blue; */
}

.career-form-container{
    width: 70vw;
    height: 100%;
    min-height: 30vh;

    border-radius: 20px;
    background-color: #ff6480;
    color: var(--white);

    margin: 0;

    /* justify-self: flex-start; */

    
    padding: 2rem 1.5rem;

    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; */

}

.career-grid-container{
    width: 100%;
    min-height: 50vh;
    
    display: grid;
    align-items: center;
    justify-content: flex-end;
    grid-template-columns: 1fr 1fr;

}

.form-container>h3{
    font-family: "Quicksand", sans-serif;
    font-size: 2.5rem;

    margin-bottom: 1rem;
}

.form-grid{
    width: 100%;
    height: 100%;

    padding: 0 1rem;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    
    font-family: "Quicksand", sans-serif;
    /* border: 1px solid red; */
}

.career-form-grid{
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.form-group{
    font-family: "Quicksand", sans-serif;
    font-weight: 500;

    display: flex;
    flex-direction: column;

    width: 100%;
    z-index: 0;
}

.form-group>label{
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

.checkbox-group{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    
    height: 100%;

    /* border: 1px solid white; */

    min-height: 2rem;

    width: 80%;
}

.checkbox-group>label{
    font-size: 1rem;
    margin-top: 0.3rem;
}

.form-control{
    width: 80%;
    
    outline: none;
    border: 1px solid white;
    border-radius: 15px;
    
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-family: "Quicksand", sans-serif;
}

.file-control{
    padding: 1rem;
    border-radius: 25px;

    font-weight: 500;
    font-size: 1.2rem;

    background-color: white;
    color: #ff6480;
}

.file-control::file-selector-button{
    border: 1px solid white;
    border-radius: 15px;
    background-color: #ff6480;
    color: white;

    padding: 0.5rem;
    font-size: 0.9rem;

    cursor: pointer;

    transition: 0.3s ease-in-out;
}

.file-control::file-selector-button:hover{
    background-color: white;
    border: 1px solid #ff6480;
    color: #ff6480;

    transition: 0.3s ease-in-out;

}

.checkbox-control{
    width: 1rem;
    height: 1rem;

    margin-right: 1rem;
    
    /* padding: 0; */
}

.form-control-textarea{
    width: 80%;
    resize: none;
}

.submit-btn{
    position: relative;

    width: fit-content;
    min-height: 2rem;

    padding: 0.5rem 1.5rem;

    margin-top: 1rem;
    margin-bottom: 5vh;

    display: flex;
    justify-content: center;
    align-items: center;

    align-self: flex-start;
    justify-self: flex-end;

    background-color: #ff6480;

    color: white;
    font-family: "Quicksand", sans-serif;
    font-size: 1.2rem;
    
    border: 2px solid white;
    border-radius: 15px;

    cursor: pointer;
    transition: 0.6s ease-in-out;

    /* z-index: 0; */
}

.submit-btn::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;

    width: 100%;
    height: 100%;
    
    background-color: #111;
    border-radius: 15px;
    display: block;

    transform: translate(8px, 8px);
    transition: 0.3s ease-in-out;
    z-index: -1;
}

.submit-btn:hover::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;

    width: 100%;
    height: 100%;
    
    background-color: #111;
    border-radius: 15px;
    display: block;

    transform: translate(2px, 2px);
    transition: 0.3s ease-in-out;
    z-index: -1;
}

.submit-btn:hover{
    /* color: #111; */
    border: 2px solid #111;
    /* background-color: white; */
    transition: 0.3s ease-in-out;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .career-form-container{
        width: 90vw;
        height: 100%;
        min-height: 30vh;
    
        border-radius: 20px;
        background-color: #ff6480;
        color: var(--white);
    
        margin: 0;
    }

    .career-grid-container{
        width: 100%;
        min-height: 50vh;
        
        display: grid;
        align-items: center;
        justify-content: flex-end;
        grid-template-columns: 1fr ;
    
    }

    .contact-form-container{
        align-items: center;
    }

    .form-container{
        width: 100%;
        border-radius: 20px 20px 0 0;
    }

    .form-control{
        width: 100%;
    }
}