.footer-container{
    position: relative;

    width: 100%;
    min-height: 40vh;

    margin-top: 25vh;
    padding: 2rem 0 1rem;

    /* background-color: #cfcfcf; */
    /* border: 2px solid blue; */

    /* background-image: url("../../../public/images/Group12.png"); */

    font-family: "Quicksand", sans-serif;
    /* background-color: #f8f8f8; */
    background-color: #fef9ef;

    /* color: var(--black); */
    color: rgb(85, 85, 85);
    /* color: var(--white); */

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.footer-logo{
    width: 7vw;
    height: auto;
}

.footer-grid-box{
    width: 100%;
    min-height: 5vh;

    margin: 2rem 0;

    /* border: 1px solid blue; */

    display: grid;
    grid-template-columns: 15% auto 33%;
}

.footer-grid-section{
    width: 100%;
    height: 100%;
    padding-left: 10vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-nav-section{
    width: min-content;
    height: min-content;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;

    /* border: 1px solid red; */
}

.footer-nav-section>p{
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.footer-nav-section>p:hover:nth-child(even){
    color: var(--brandColourRed);
    transition: 0.3s ease-in-out;
}

.footer-nav-section>p:hover:nth-child(odd){
    color: #5050f5;
    transition: 0.3s ease-in-out;
}

.footer-grid-section>a{
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* border: 1px solid red; */
}

.footer-grid-section>a>p{
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    margin-left: 1rem;
    transition: 0.3s ease-in-out;
}

.footer-grid-section>a:hover .footer-link{
    color: #5050f5;
    transition: 0.3s ease-in-out;
}

.footer-grid-section>p{
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.footer-icon{
    font-size: 1.5rem;
}

.small-icon{
    font-size: 1.2rem;
}

.footer-grid-section>a:hover .small-icon{
    color: var(--brandColourRed);
    transition: 0.3s ease-in-out;
}

.social-icon-box{
    width:auto;
    min-height: 2rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 1rem;
}

.instagram{
    font-size: 1.8rem;
    transition: 0.3s ease-in-out;
}

.facebook{
    font-size: 1.6rem;
    transition: 0.3s ease-in-out;
}

.instagram:hover{
    color: var(--brandColourRed);
    transition: 0.3s ease-in-out;
}

.facebook:hover{
    color: #5050f5;
    transition: 0.3s ease-in-out;
}

.rights-reserved{
    margin-top: 1rem;
    font-family: "Quicksand", sans-serif;
    color: #7c7c7c;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
    .footer-grid-box{
        /* display: grid; */
        grid-template-columns: 1fr;
        align-items: center;
        justify-content:center;

        padding: 0;
    }

    .footer-grid-section{
        padding-left: 10vw;
        padding-right: 10vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        /* border: 1px solid red; */
    }

    .footer-grid-section>a{
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        /* border: 1px solid red; */
    }

    .footer-grid-section>a>p{
        text-align: center;
    }
    
    .footer-grid-section>a>p{
        margin-left: 0rem;
    }
    .footer-logo{
        width: 30vw;
        height: auto;

        margin-bottom: 5vh;
    }

    .footer-nav-section{
        padding: 5vh 5vw 0;

        justify-self: center;
    
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        justify-content: center;
        align-items: center;
    }

    .footer-nav-section>p{
        font-size: 0.9rem;
    }

    .rights-reserved{
        margin-top: 0rem;
        font-size: 0.8rem;
    }
}