/* @import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700');
@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

@font-face{
  src: url(./fonts/Chromate-Regular.ttf);
  font-family: Chromate-Regular;
}

:root{
  --backgroundColour1: #FFFFFF;
  --backgroundColour2:#f5cac3;
  --backgroundColour3:#669bbc;

  /* --brandColourRed: #EC1B24; */
  --brandColourRed:#ff6480;
  /* --brandColourRed:#FABED8; */
  --brandColourBlue: #292875;
  
  --navbarHeight: 12vh;
  
  --black:#000000;
  --white:#ffffff;
}

a{
  text-decoration: none;
  color: inherit;
}

*{
  box-sizing: border-box;
  padding:0;
  margin:0;
  /* font-family: "Spectral", serif; */
  font-family: "Noto Serif Display", serif;

  -webkit-tap-highlight-color: transparent
}

.container{
  width: 100%;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: var(--backgroundColour1);
}

.full-content-section{
  position: relative;

  width: 100%;
  min-height: 100vh;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* justify-content: center; */
  align-items: center;

  /* border: 1px solid blue; */
}

.not-found-box{

  /* background-color: red; */
  justify-content: center;
  /* align-self: center; */
}

.full-content-section>h1{
  letter-spacing: 0.0015em;
	font-size: 5em;
    /* font-family: "Sofia", cursive; */
    font-family: "Quicksand", sans-serif;

    font-weight: 700;
    color: var(--brandColourRed);
    --shadowMain : #ffafcc;
    --shadowLight: #ffc8dd;
	text-shadow: 0 1px var(--shadowMain), -1px 0 var(--shadowLight), -1px 2px var(--shadowMain), -2px 1px var(--shadowLight),
		-2px 3px var(--shadowMain), -3px 2px var(--shadowLight), -3px 4px var(--shadowMain), -4px 3px var(--shadowLight),
		-4px 5px var(--shadowMain), -5px 4px var(--shadowLight), -5px 6px var(--shadowMain), -6px 5px var(--shadowLight),
		-6px 7px var(--shadowMain), -7px 6px var(--shadowLight), -7px 8px var(--shadowMain), -8px 7px var(--shadowLight);
	text-align: center;
}

.full-content-section>h2{
  font-family: "Quicksand", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
    color: var(--brandColourRed);
}

.full-img-container{
  position: relative;
  width: 100%;
  height: 90vh;

  /* border: 1px solid red; */
}

.full-content-section-img{
  width: 100%;

  /* margin: -0vh 0; */
  /* margin-top: -15vh; */
  height: 100%;

  object-fit: cover;
  object-position: 0% 30%;
  
  /* border: 1px solid red; */
}

.half-content-section{
  position: relative;

  width: 100%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* background-color: var(--backgroundColour3); */
}

.big-heading-container{
  width: 100%;
  height: inherit;

  
  /* padding-left: 3rem; */
  
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 5rem;
  color: var(--white);
}

.half-grid-section{
  position: relative;
  
  width: 100%;
  min-height: 80vh;

  /* padding: 2vh 0; */

  display: grid;
  grid-template-columns: 1fr 1fr;

  /* border: 1px solid red; */
}

.half-grid-content-box{
  position: relative;
  width: 100%;
  height: 100%;

  padding-left: 10vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.half-grid-content-box>h1{
  letter-spacing: 0.0015em;
	font-size: 4rem;
    /* font-family: "Sofia", cursive; */
    font-family: "Quicksand", sans-serif;

    font-weight: 700;
    color: var(--brandColourRed);
    --shadowMain : #ffafcc;
    --shadowLight: #ffc8dd;
	text-shadow: 0 1px var(--shadowMain), -1px 0 var(--shadowLight), -1px 2px var(--shadowMain), -2px 1px var(--shadowLight),
		-2px 3px var(--shadowMain), -3px 2px var(--shadowLight), -3px 4px var(--shadowMain), -4px 3px var(--shadowLight),
		-4px 5px var(--shadowMain), -5px 4px var(--shadowLight), -5px 6px var(--shadowMain), -6px 5px var(--shadowLight),
		-6px 7px var(--shadowMain), -7px 6px var(--shadowLight), -7px 8px var(--shadowMain), -8px 7px var(--shadowLight);
	/* text-align: center; */


  /* border: 1px solid red; */
}

.half-grid-content-box>p{
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  color: var(--brandColourRed);

  max-width: 30vw;
  word-break: normal;

  margin-bottom: 0.8rem;
}

.half-grid-img-box{
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* border: 1px solid red; */
}

.half-grid-img{
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* border-radius: 0 50px 50px 0 ; */
}

.right-half-img-box{
  border-radius: 50% 0px 0px 0px;
  /* border: 5px solid red; */
}

.header-section{
  position: relative;
  margin: 20vh 0 5vh;
  padding: 1rem 3rem 1rem 2rem;
  /* background-color: #ff6480; */

  /* border-radius: 50%;
  border: 5px solid #FF80BF ; */
}

.header-section>h1{
  /* font-family: "Sofia", cursive; */
  /* font-weight: 400; */
  /* font-style: normal; */
  font-size: 5rem;
  text-transform: uppercase;
  word-spacing: 1.5rem;
  /* color: #ff4d6d; */

  /* font-weight: 700; */
  /* color: #ffc8dd; */
  /* color: #F9f1cc; */
  font-family:"Quicksand", sans-serif;
  /* font-family: "Calligraffitti", cursive; */
  /* text-shadow: -15px 5px 20px #ced0d3; */
  letter-spacing: 0.02em;

  /* --pinkShadow: #ca2075;
  text-shadow: 
    2px 2px 0px  #FF80BF, 
    4px 4px 0px #F9f1cc,
    6px 6px 0px #6868AC,
    8px 8px 0px var(--pinkShadow), 
    10px 10px 0px var(--pinkShadow), 
    12px 12px 0px var(--pinkShadow); */


  font-weight: 700;
  color: var(--brandColourRed);
  --shadowMain : #fc9cbf;
  --shadowLight: #ffc8dd;
	text-shadow: 0 1px var(--shadowMain), -1px 0 var(--shadowLight), -1px 2px var(--shadowMain), -2px 1px var(--shadowLight),
		-2px 3px var(--shadowMain), -3px 2px var(--shadowLight), -3px 4px var(--shadowMain), -4px 3px var(--shadowLight),
		-4px 5px var(--shadowMain), -5px 4px var(--shadowLight), -5px 6px var(--shadowMain), -6px 5px var(--shadowLight),
		-6px 7px var(--shadowMain), -7px 6px var(--shadowLight), -7px 8px var(--shadowMain), -8px 7px var(--shadowLight);
	text-align: center;
}

.two-grid-box{
  width: 70vw;
  height: 100%;

  margin-top: 5vh;

  display: grid;
  grid-template-columns: 1fr 1fr;

  /* bo */
  /* column-gap: 5vw; */
  /* border: 1px solid red; */
}

.top-bg{
  position: absolute;
  top: -25vh;
  left: 0;

  width: 100%;
  height: 25vh;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.top-bg>img{
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
  object-fit: contain;
}


/*///////////////////////////////////////////////*/
/*/////////////////ScrollBar/////////////////////*/
/*///////////////////////////////////////////////*/
 
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #e24545; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff6480;
  border: 2px solid white;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 


/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
  .half-grid-section{
  grid-template-columns: 1fr;

  }


  .half-grid-img-box{
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* border: 1px solid red; */
  }

  .half-grid-content-box{
    /* border: 2px solid red; */
    margin-bottom: 20vh;
    padding-top: 5vh;
    justify-content: flex-start;
  }

  .half-grid-content-box>p{
    font-family: "Quicksand", sans-serif;
    font-size: 1rem;
    color: var(--brandColourRed);
  
    max-width: 80vw;
    word-break: normal;
  
    margin-bottom: 0.8rem;
  }

  .half-grid-content-box>h1{
    letter-spacing: 0.0015em;
    font-size: 2.5rem;
  
    font-weight: 600;
  }
  .header-section>h1{
    font-size: 3rem;
  }

  .two-grid-box{
    width: 90vw;
    height: 100%;
  
    margin-top: 5vh;
  
    display: grid;
    grid-template-columns: 1fr;
  
    /* bo */
    /* column-gap: 5vw; */
    /* border: 1px solid red; */
  }
}