.partner-dd-container{
    position: relative;
    width: 100%;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;

    padding: 5vw;

    grid-column: 1/ span 2;

    /* background-color: var(--brandColourBlue); */

    /* border: 2px solid red; */
}

.partner-dd-mobile-container{
    width: 100%;
    height: auto;

    padding: 5vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background-color: red; */
}

.partner-list-box{
    width: 100%;
    height: 100%;
}

.partner-cell{
    width: 100%;
    height: auto;
    min-height: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.partner-cell-box{
    width: 100%;
    min-height: 1.5rem;
    height: auto;

    padding: 1.5rem 1rem;

    border-bottom: 1px solid #ff6480;

    display: grid;
    grid-template-columns: 2rem auto ;
    gap: 1rem;
    align-items: center;

    cursor: pointer;

    transition: 0.3s ease-in-out;
}

.partner-cell-text{
    font-family: "Quicksand", sans-serif;
    font-size: 1.5rem;
    color: #ff6480 ;
    transition: 0.3s ease-in-out;
}

.cell-num{
    font-size: 2rem;
}

.partner-cell-box:hover{
    background-color: #ff6480;
    border-bottom:1px solid white;
    transition: 0.3s ease-in-out;
}

.partner-cell-box:hover .partner-cell-text{
    color: white;
    transition: 0.3s ease-in-out;
}

.partner-info-box{
    position: sticky;
    top: 10vh;
    left: 0;
    width: 100%;
    min-height: 45vh;
    height: fit-content;

    padding-bottom: 5vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: #3f3f3f; */

    
}
.partner-info-mobile-box{
    position: relative;
    height: auto;
    min-height: 30vh;
    padding-left: 9vw;

    grid-column: 1/span 2;

    align-self: flex-start;
    color: white;

    margin-bottom: 2vh;
    transition: 0.3s ease-in-out;
}

.partner-info-heading{
    width: 100%;
    min-height: 10vh;

    padding-left: 3vw;
    padding-bottom: 0.5rem;
    
    display: grid;
    grid-template-columns: 3rem auto;
    gap: 2rem;

    align-items: center;

    /* border: 1px solid red; */
}

.partner-info-heading>p{
    font-family: "Quicksand", sans-serif;
    color: #ff6480;
    font-size: 2rem;
}

.partner-info-details{
    width: 100%;
    padding-left: 9vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.partner-info-mobile-details{
    width: 100%;
    height: auto;

    padding-bottom: 5vh;
    /* border: 1px solied blue; */
}

.partner-info-para{
    font-family: "Quicksand", sans-serif;
    font-size: 1.5rem;
    color: #ff6480;

    /* border: 1px solid red; */
}

.partner-info-num{
    font-size: 2.5rem;
}